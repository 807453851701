
import React from 'react';
import classes from "./Home.module.css";
import { Card, Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import About from '../../img/about.png'


export default function AboutUs() {
    return (

        <Container id="About" className={classes.about}>
            <Row>
                <Col sm={8}>

                    <h1 className={classes.subHeader} style={{ textAlign: "center" }}>About Us</h1>

                    <p className={classes.cardTxt}>

                        We’ve had the foresight to find new ways to make lives better.<br />
                        From creating new categories to starting new conversations,<br />
                        we are constantly innovating our products and our practices
                        to serve and care for the ever-changing needs of the people
                        we touch at all stages.<br />
                        We strive to continually improve our products and ensure<br />
                        that our products are above market related quality.<br />

                    </p>

                </Col>
                <Col sm={4}>
                    <img src={About} className={classes.aboutImg}></img>
                </Col>
            </Row>

        </Container>

    )
}

