import React from "react";
import Header from "./Header";
import NavBar from "./AppBar";
import Products from "./Products";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";

export default function Home() {
    return (
        <>
            <NavBar />
            <Header />
            <Products />
            <AboutUs />
            <ContactUs />
           
        </>
    )
}