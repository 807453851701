import React from 'react';
import { useNavigate } from "react-router-dom";
import classes from "./Home.module.css";
import Product1 from "../../img/11.png";
import Product2 from "../../img/cd1.jpg";
import { Button, Container } from 'react-bootstrap';
import List1 from "../../Doc/priceList1.pdf"
import List2 from "../../Doc/priceList2.pdf"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Products() {
    let navigate = useNavigate();
    const ProductPage = () => {
        navigate('/Products')
    };
    return (
        <div className={classes.productContainer} id="Products">
            <h1 className={classes.subHeader} style={{paddingTop:"5rem"}}>Our Products</h1>
            <div style={{ margin: "auto" }}>
                <Container>
                    <Row>
                        <Col sm={6}>
                            <img src={Product1} className={classes.productImg}></img>
                            <h4>Spring Soft Toilet Paper</h4>


                            <a href={List1} without rel="noopener noreferrer" target="_blank">
                                <Button size="lg" className={classes.showBtn} >
                                    View Price List
                                </Button>
                            </a>
                        </Col>

                        <Col sm={6}>
                            <img src={Product2} className={classes.productImg}></img>


                            <h4>Spring Soft Cleaning Detergents</h4>

                            <a href={List2} without rel="noopener noreferrer" target="_blank">
                                <Button size="lg" className={classes.showBtn} >
                                    View Price List
                                </Button>
                            </a>

                        </Col>

                    </Row>
                </Container>
            </div>


        </div>
    )
}

