
import React from "react";
import classes from "./Home.module.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import headerImg from "../../img/t99.png"
import 'bootstrap/dist/css/bootstrap.min.css';
import './header.css';


export default function Header() {

    return (

        <div class="header">
            <div >

                <Container fluid id="Home">
                    <Row style={{paddingTop:"7rem"}}>
                        <Col sm={8}>

                            <h1 className={classes.title}>Leader In Manufacturing High Quality Toilet Tissue</h1>
                            <p className={classes.subTitle}>Our mission is to provide our clients with the best possible<br />
                                quality paper tissue products. We aim to achieve this through the constant<br />
                                development and acquisition of new machinery and processes, <br />
                                the expansion of our product line  and the adherence to our core values.
                            </p>
                        </Col>
                        <Col sm={4}>
                            <img
                                className={classes.headerImg}
                                src={headerImg}
                                alt="logo"
                            />

                        </Col>
                    </Row>

                </Container>
            </div>


            <div>
                <svg class="waves" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g class="parallax">
                        <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                        <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use href="#gentle-wave" x="48" y="7" fill="#fff" />
                    </g>
                </svg>
            </div>


        </div>



    )

}