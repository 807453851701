import * as React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { Nav } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import classes from "./Home.module.css";
import Logo from "../../img/logo4.png"
export default function NavbarBar() {
    return (
        <div>
            <div className={classes.logoDiv}>
                <img src={Logo} className={classes.logoStyle} alt="logo"></img>
            </div>
            <Navbar collapseOnSelect expand="lg" className={classes.navStyle} >
                <Container>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className={classes.navC}>

                        <Nav  className={classes.navC}>
                            <Nav.Link href="\#Home" className={classes.navF}> Home </Nav.Link>
                            <Nav.Link href="\#Products" className={classes.navL}> Our Products </Nav.Link>
                            <Nav.Link href="\#About" className={classes.navL}> About Us </Nav.Link>
                            <Nav.Link href="\#Contact" className={classes.navL}>   Contact </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}
